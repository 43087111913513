import { combineReducers } from 'redux';


import counterReducer from './Counter/counter.reducer';


const reducer = combineReducers({

    counter: counterReducer,

});

export default reducer;
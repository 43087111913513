import './App.css';
import Router from "./Router"
import { Preloader, Placeholder } from 'react-preloading-screen';
import { SwapSpinner } from "react-spinners-kit";


function App() {
  return (
    <div className="App">
      {/* <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.js</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>

        
      </header> */}
     

      <Preloader>
      <Router/>
                <Placeholder>
                <SwapSpinner size={50} color="#686769" loading={true} />
                </Placeholder>
            </Preloader>
      {/* <Layout/> */}
    </div>
  );
}

export default App;

import React, { Suspense, lazy } from "react";
import { Router, Switch, Route } from "react-router-dom";
import { history } from "./history";
import { connect } from "react-redux";



import {Header} from './Layout/Header'
import {Footer} from './Layout/Footer'
import ScrollToTop from './Layout/ScrollToTop'

import "./css/bootstrap-grid.css"
// import "./css/theme-dark.css"
import "./css/main.css"
import "./css/bootstrap-grid.css"



// Route-based code splitting

const home = lazy(() => import("./Views/Home"));

const about = lazy(()=> import  ('./Views/About'));
const projects = lazy(()=> import  ('./Views/Projects'));
const projectDetails = lazy(()=> import('./Views/ProjectDetails'));
const contact = lazy(()=> import('./Views/Contact'));
const careers = lazy(()=> import('./Views/Careers'));
const leadership = lazy(()=> import('./Views/Team'));
const services = lazy(()=> import('./Views/Services'));
const serviceDetails = lazy(()=> import('./Views/ServiceDetails'));







// Set Layout and Component Using App Route
const RouteConfig = ({
  component: Component,
  fullLayout,
  permission,
  user,
  ...rest
}) => (
  <Route
    {...rest}
    render={(props) => {

      return (
        
        <Suspense fallback={   null
            //  <BounceLoader color="#19258B" loading={true} size={50}/>
      }>
           {/* <div className="no-js">   */}
            {/* <Menu/> */}
                <div id="content-wrapper">   

                {/* <div className="animsition"> */}

                        <Header />  
                        <Component {...props} />
                        <Footer />  
                     
                    </div>  
                   
                {/* </div>   */}
             {/* </div>   */}
         
        </Suspense>
      
      
      );
    }}
  />
);
const mapStateToProps = (state) => {
  return {
    // user: state.auth.login.userRole,
  };
};

const AppRoute = connect(mapStateToProps)(RouteConfig);

class AppRouter extends React.Component {

  componentDidMount() {
    function loadScript(url, callback){


      let script = document.createElement("script")
      script.type = "text/javascript";
   
      if (script.readyState){  //IE
          script.onreadystatechange = function(){
              if (script.readyState ==="loaded" ||
                      script.readyState ==="complete"){
                  script.onreadystatechange = null;
                  callback();
                  console.log("LOADED")
              }

          };
      } else {  //Others
          script.onload = function(){
              callback();
          };
      }}

      loadScript("./js/jquery-3.5.1.min.js",()=>{})
      loadScript("./js/jquery.fancybox.min.js",()=>{})
      loadScript("./js/jquery.pagepiling.js",()=>{})
      loadScript("./js/aos.js",()=>{})
      loadScript("./js/jquery.easy_number_animate.js",()=>{})
      loadScript("./js/isotope.pkgd.min.js",()=>{})
      loadScript("./js/packery-mode.pkgd.min.js",()=>{})
      loadScript("./js/swiper-bundle.min.js",()=>{})
      loadScript("./js/animsition.min.js",()=>{})

      loadScript("js/main.js",()=>{})

   
  

  }


  render() {
    let routes;
    // if ( this.props.isAuthenticated != null){
    routes = (
      <Router history={history}>
         <ScrollToTop />
        <Switch>
        <AppRoute exact path="/" component={home} />


          <AppRoute exact path="/about" component={about} />
          <AppRoute exact path="/contact" component={contact} />
          <AppRoute exact path="/projects" component={projects} />
          <AppRoute exact path="/projectDetails" component={projectDetails} />
          <AppRoute exact path="/careers" component={careers} />
          <AppRoute exact path="/leadership" component={leadership} />
          <AppRoute exact path="/services" component={services} />
          <AppRoute exact path="/serviceDetails" component={serviceDetails} />






         
        </Switch>
      </Router>
    );

    return <div>{routes}</div>;
  }
}

// const mapStateToPropss = (state) => {
//   return {
//     isAuthenticated: state.auth.login.tokens,
//   };
// };

export default (AppRouter);

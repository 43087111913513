
import React, { Component } from 'react'  
import "../css/main.css"
import "../css/bootstrap-grid.css"
import {history} from "../history"

  
export class Menu extends Component {  


  

    render(){
 
      let pathname = history.location.pathname
        return(
  
 <div className="mobile-canvas __js_mobile-canvas">
 <button className="mobile-canvas__close" type="button">
   <svg width="24" height="24">
     <use xlinkHref="#close"></use>
   </svg>
   <span className="visually-hidden">Close menu</span>
 </button>


 <nav className="mobile-canvas__nav navigation">
   <ul className="navigation__list">
     
     {pathname==='/'?  <li className="navigation__item"  style={{ outline: "none",
    color: "#262628",
    position: "relative",
    backgroundColor: "#a7a7ac9b"
  }}>
  
    <a href='/'  style={{all: 'unset'}}>
       <div className="navigation__link" href='/'  onClick={()=> history.push("/")}>Home</div> 
       </a>
       
     </li> :  <li className="navigation__item">
     <a href='/'  style={{all: 'unset'}}>
       <div className="navigation__link"  href='/'   onClick={()=> history.push("/")}>Home</div> 
       </a>
       
     </li> }

     {pathname==='/about'?  <li className="navigation__item"  style={{ outline: "none",
    color: "#262628",
    position: "relative",
    backgroundColor: "#a7a7ac9b"
  }}>
      <a href='/about'   style={{all: 'unset'}}>
       <div className="navigation__link"  onClick={()=> history.push("/about")}>About</div> 
       </a>
       
     </li> :  <li className="navigation__item">
     <a href='/about'   style={{all: 'unset'}}>
       <div className="navigation__link"  onClick={()=> history.push("/about")}>About</div> 
       </a>
       
     </li> }

     {pathname==='/services'?  <li className="navigation__item"  style={{ outline: "none",
    color: "#262628",
    position: "relative",
    backgroundColor: "#a7a7ac9b"
  }}>
        <a   href='/services'   style={{all: 'unset'}}>
       <div className="navigation__link"
        href='/services' 
        onClick={()=> history.push("/services")}
       >Services</div> 
       </a>
       
     </li> :  <li className="navigation__item">
     <a   href='/services'   style={{all: 'unset'}}>
       <div
        href='/services' 
        className="navigation__link"  
       onClick={()=> history.push("/services")}
       >Services</div> </a>
       
     </li> }

     {pathname==='/projects' || pathname==='/projectDetails/'?  <li className="navigation__item"  style={{ outline: "none",
    color: "#262628",
    position: "relative",
    backgroundColor: "#a7a7ac9b"
  }}>
     <a   href='/projects'   style={{all: 'unset'}}>
       <div className="navigation__link" onClick={()=> history.push("/projects")}>Projects</div> 
       </a>
       
     </li> :  <li className="navigation__item">
     <a   href='/projects'   style={{all: 'unset'}}>
       <div className="navigation__link" href="/projects"  onClick={()=> history.push("/projects")}>Projects</div> 
       </a>
       
     </li> }

    
    
     {pathname==='/careers'?  <li className="navigation__item"  style={{ outline: "none",
    color: "#262628",
    position: "relative",
    backgroundColor: "#a7a7ac9b"
  }}>
     <a    href='/careers'   style={{all: 'unset'}}>
       <div className="navigation__link"  onClick={()=> history.push("/careers")}>Careers</div> 
       </a>
       
     </li> :  <li className="navigation__item">
     <a    href='/careers'   style={{all: 'unset'}}>

       <div className="navigation__link" onClick={()=> history.push("/careers")}>Careers</div> 
       </a>
     </li> }
   
     
     {pathname==='/contact'?  <li className="navigation__item"  style={{ outline: "none",
    color: "#262628",
    position: "relative",
    backgroundColor: "#a7a7ac9b"
  }}>
     <a href='/contact'   style={{all: 'unset'}}>
       <div className="navigation__link" 
      
        onClick={()=> history.push("/contact")}
       >Contact</div> 
       </a>
       
     </li> :  <li className="navigation__item">
     <a href='/contact'   style={{all: 'unset'}}>

       <div className="navigation__link"   href="/contact"
       onClick={()=> history.push("/contact")}
       >Contact</div> 
       </a>
       
     </li> }


   </ul>
 </nav>
 <div className="mobile-canvas__bottom">
   <a className="mobile-canvas__phone" href="tel:+72126742510">+7 (212) 674-25-10</a>
   <div className="mobile-canvas__copy">
     © 2021 <span>EuroGrouo.</span> All Rights Reserved.
     <br/>Development by <span>Arotech</span>
   </div>
  
   <ul className="mobile-canvas__social social">
     <li className="social__item">
       <a className="social__link" href="#" target="_blank">
         <svg width="20" height="20" aria-label="facebook icon">
           <use xlinkHref="#facebook"></use>
         </svg>
         <span className="visually-hidden">facebook</span>
       </a>
     </li>
     <li className="social__item">
       <a className="social__link" href="#" target="_blank">
         <svg width="20" height="20" aria-label="twitter icon">
           <use xlinkHref="#twitter"></use>
         </svg>
         <span className="visually-hidden">twitter</span>
       </a>
     </li>
     <li className="social__item">
       <a className="social__link" href="#" target="_blank">
         <svg width="20" height="20" aria-label="google-plus icon">
           <use xlinkHref="#google-plus"></use>
         </svg>
         <span className="visually-hidden">google-plus</span>
       </a>
     </li>
     <li className="social__item">
       <a className="social__link" href="#" target="_blank">
         <svg width="20" height="20" aria-label="linkedin-original icon">
           <use xlinkHref="#linkedin-original"></use>
         </svg>
         <span className="visually-hidden">linkedin-original</span>
       </a>
     </li>
   </ul>
 </div>
</div>
)
}
}

export default Menu
import React, { Component } from "react";
import "../css/main.css";
import "../css/bootstrap-grid.css";

export class Footer extends Component {
  render() {
    return (
      <footer
        className="footer"
        style={{ backgroundImage: 'url("img/footer.webp")' }}
      >
        <div
          className="footer__inner container"
          style={{ backgroundColor: "rgb(38,38,40,0.94)", maxWidth: "inherit" }}
        >
          <div className="row">
            <div className="footer__column col-12  col-sm">
              <a href="#" className="footer__logo logo--borderless logo">
                <img
                  src="/img/logos/Logo_white_footer.png"
                  alt="Euro Group Vertical Logo"
                  style={{ width: "140px" }}
                ></img>
              </a>
            </div>

            <div
              className="footer__column footer__column col-12 col-md col-lg-3 col-xl-2"
              style={{ display: "flex" }}
            >
              <ul
                className="footer__social social"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "flex-end",
                }}
              >
                <li className="social__item">
                  <a className="social__link" href="mailto:info@eurogroup.org">
                    <i
                      className="fas fa-envelope"
                      style={{ fontSize: "15px", color: "darkgray" }}
                    ></i>

                    <span className="visually-hidden">email</span>
                  </a>
                </li>

                <li className="social__item" style={{ marginLeft: "0px" }}>
                  <a
                    className="social__link"
                    rel="noreferrer"
                    href="https://www.facebook.com/EuroGroupEngineeringConsultancy/"
                    target="_blank"
                  >
                    <i
                      className="fab fa-facebook"
                      style={{ fontSize: "15px", color: "darkgray" }}
                    ></i>
                    <span className="visually-hidden">facebook</span>
                  </a>
                </li>

                <li className="social__item" style={{ marginLeft: "0px" }}>
                  <a
                    className="social__link"
                    href="https://www.linkedin.com/company/euro-group-for-engineering-consultancy"
                    rel="noreferrer"
                    target="_blank"
                  >
                    <i
                      className="fab fa-linkedin"
                      style={{ fontSize: "15px", color: "darkgray" }}
                    ></i>

                    <span className="visually-hidden">linkedin-original</span>
                  </a>
                </li>
              </ul>
            </div>
          </div>

          <div className="footer__column col-12 col-lg order-2 order-lg-0">
            <div className="footer__copyright">
              Copyright © 2023 EURO GROUP. All Rights Reserved.
              {/* <br/>All Rights Resevered */}
            </div>
          </div>
        </div>
      </footer>
    );
  }
}

export default Footer;

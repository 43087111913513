import React from "react";
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css'; 
import { Provider } from 'react-redux';
import store from './redux/store'; 




ReactDOM.render(
  <React.StrictMode>
   < Provider store={store}>
    <App />
    </ Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// // If you want to start measuring performance in your app, pass a function
// // to log results (for example: reportWebVitals(console.log))
// // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// ReactDOM.render(
//   <Provider store={store}>
//     {/* <PersistGate persistor={persistor}></PersistGate> */}
//     {/* <Suspense fallback={<Spinner/>}> */}
//       <Layout>
//         <LazyApp />
//       </Layout>
//     {/* </Suspense> */}
//   </Provider>,
//   document.getElementById("root")
// );

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
import React, { Component } from "react";
import "../css/main.css";
import Menu from "./Menu";
// import "./header.css"
import "../css/bootstrap-grid.css";
import { history } from "../history";
import { slide as Menu2 } from "react-burger-menu";

export class Header extends Component {
  state = {
    nav: false,
  };

  render() {
    let pathname = history.location.pathname;

    return (
      <header className="header __js_fixed-header" id="header">
        <div className="header__inner">
          <img
            alt="Euro Group Engineering Consultancy Logo"
            className="logo__large"
            style={{ width: "100px" }}
            src="/img/logos/Logo_white.png"
            onClick={() => history.push("/")}
          />

          <div className="container"></div>

          <div
            className="header__menu-toggle menu-toggle"
            onClick={() => console.log("Clicked")}
            type="button"
          >
            {/* <span className="visually-hidden">Menu</span> */}
            <Menu2 right>
              {pathname === "/" ? (
                <a
                  id="home"
                  className="menu-item"
                  style={{ color: "#19258b" }}
                  href="/"
                  onClick={() => history.push("/")}
                >
                  Home
                </a>
              ) : (
                <a
                  className="menu-item"
                  style={{ color: "#262628" }}
                  href="/"
                  onClick={() => history.push("/")}
                >
                  Home
                </a>
              )}
              {pathname === "/about" ? (
                <a
                  id="about"
                  className="menu-item"
                  style={{ all: "unset", color: "#19258b" }}
                  href="/about"
                  onClick={() => history.push("/about")}
                >
                  About
                </a>
              ) : (
                <a
                  id="about"
                  className="menu-item"
                  href="/about"
                  onClick={() => history.push("/about")}
                >
                  About
                </a>
              )}
              {pathname === "/services" ? (
                <a
                  id="about"
                  className="menu-item"
                  style={{ color: "#19258b" }}
                  href="/services"
                  onClick={() => history.push("/services")}
                >
                  Services
                </a>
              ) : (
                <a
                  id="about"
                  className="menu-item"
                  href="/services"
                  onClick={() => history.push("/services")}
                >
                  Services
                </a>
              )}
              {pathname === "/projects" || pathname === "/projectDetails/" ? (
                <a
                  id="about"
                  className="menu-item"
                  style={{ color: "#19258b" }}
                  href="/projects"
                  onClick={() => history.push("/projects")}
                >
                  Projects
                </a>
              ) : (
                <a
                  id="about"
                  className="menu-item"
                  href="/projects"
                  onClick={() => history.push("/projects")}
                >
                  Projects
                </a>
              )}
              {pathname === "/careers" ? (
                <a
                  id="about"
                  className="menu-item"
                  style={{ color: "#19258b" }}
                  href="/careers"
                  onClick={() => history.push("/careers")}
                >
                  Careers
                </a>
              ) : (
                <a
                  id="about"
                  className="menu-item"
                  href="/careers"
                  onClick={() => history.push("/careers")}
                >
                  Careers
                </a>
              )}
              {pathname === "/contact" ? (
                <a
                  id="contact"
                  className="menu-item"
                  href="/contact"
                  style={{ color: "#19258b" }}
                  onClick={() => history.push("/contact")}
                >
                  Contact
                </a>
              ) : (
                <a
                  id="contact"
                  className="menu-item"
                  href="/contact"
                  onClick={() => history.push("/contact")}
                >
                  Contact
                </a>
              )}
            </Menu2>
          </div>
        </div>
        <Menu />
      </header>
    );
  }
}
export default Header;
